/* RESET */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* General Styles */
body {
    margin: 0;
    background-color: black;
    color: white;
    font-family: Arial, sans-serif;
}

::selection {
    background: #6b00ff;
    color: white;
}

/* BookCall Section */
.bookcall {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}

/* 
  OPTIONAL: If your Navbar is fixed at the top and you need some top padding,
  you can add it here (uncomment or adjust as needed):
  padding-top: 5rem; 
*/

/* BookCall Hero Container */
.bookcall-hero-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 4rem 2rem;
}

/* Optional glowing background orb */
.glowing-planet {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 600px;
    background: radial-gradient(circle, rgba(107, 0, 255, 0.5), rgba(0, 0, 0, 0));
    border-radius: 50%;
    filter: blur(50px);
    pointer-events: none;
}

.bookcall-hero-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.bookcall-hero-title {
    width: 100%;
    max-width: 1200px;
    margin: 3vw auto 2rem auto;
    padding: 0 1rem;
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 3rem);
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bookcall-hero-content p {
    color: #bbb;
    font-size: 1.125rem;
    margin-bottom: 1rem;
}

/* Booking Container */
.booking-container {
    max-width: 900px;
    margin: 2rem auto;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(107, 0, 255, 0.3);
    border-radius: 1rem;
    backdrop-filter: blur(10px);
}

/* Widget Wrapper for Calendly 
   We'll set a fixed height in VH to ensure consistent sizing.
*/
.widget-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70vh;
    /* Default height on large screens */
    overflow: hidden;
}

.widget-wrapper iframe {
    width: 100%;
    height: 100%;
    border: none;
}

/* Features Row */
.features {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: #bbb;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.feature-item .icon {
    width: 16px;
    height: 16px;
    color: #6b00ff;
}

/* "Still not sure?" Button */
.to-whyus-button {
    margin-top: 2rem;
    background: linear-gradient(to right, #a78bfa, #06b6d4);
    border: none;
    color: #fff;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
}

.to-whyus-button:hover {
    transform: scale(1.05);
}

/* MEDIA QUERIES */

/* --- For Tablets up to 768px wide --- */
@media (max-width: 768px) {
    .bookcall-hero-container {
        padding: 2rem 1rem;
    }

    .bookcall-hero-title {
        font-size: 2rem;
        margin: 2rem auto;
    }

    .bookcall-hero-content p {
        font-size: 1rem;
    }

    .booking-container {
        padding: 1rem;
        max-width: 100%;
    }

    /* Reduce Calendly widget height slightly for tablets */
    .widget-wrapper {
        height: 60vh;
    }

    .features {
        flex-direction: column;
        gap: 1rem;
    }

    .to-whyus-button {
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 1rem;
    }
}

/* --- For Mobile up to 480px wide --- */
@media (max-width: 480px) {

    /* Further reduce Calendly widget height for small phones */
    .widget-wrapper {
        height: 50vh;
    }
}