#about,
#contact,
#landing {
    scroll-margin-top: 15vh;
}

.navbar {
    border-radius: 100px;
    transition: all 0.2s ease;
    width: 50vw;
    z-index: 1000;
}

.navbar-top {
    position: absolute;
    top: 2vw;
    left: 0;
    padding: 1rem;
    color: #fff;
    background-color: transparent;
    transform: translateX(0);
    transition: all 0.5s ease;
}

.navbar-scrolled {
    border-radius: 100px;
    position: fixed;
    top: 4vh;
    left: 50%;
    transform: translateX(-50%);
    width: 60vw;
    background-color: #fff;
    color: black;
    padding: 1rem 0;
    transition: all 0.7s ease;
}

.nav-list {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    transition: max-height 0.3s ease;
    margin: 0;
    padding: 0;
}

.nav-list li {
    margin: 0.5rem 0;
}

.nav-list button {
    background: none;
    border: none;
    color: inherit;
    font-weight: bold;
    font-size: 1.2vw;
    cursor: pointer;
    position: relative;
    font-family: sans-serif;
    padding: 0.6em 1.2em;
}

.nav-list button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width 0.3s ease;
}

.nav-list button:hover::after {
    width: 100%;
}

.nav-list button:hover {
    color: #ddd;
}

.navbar-scrolled .nav-list button:hover {
    color: #333;
}

/* Mobile Header */
.mobile-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    z-index: 1001;
}

.logo-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

/* Hamburger Menu (Desktop version) */
.hamburger {
    margin-right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 110px;
    height: 35px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1100;
    transition: all 0.3s ease;
}

.hamburger .bar {
    width: 100%;
    height: 4px;
    background-color: white;
    transition: all 0.3s ease;
}

.hamburger.open .bar:nth-child(1) {
    transform: translateY(11px) rotate(45deg);
}

.hamburger.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
    transform: translateY(-11px) rotate(-45deg);
}

.orbilaw-navbar-logo {
    width: 20%;
    opacity: 0;
    /* hide logo as idea */
}

/* Mobile Navigation Overlay */
.mobile-nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(39px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 999;
}

.mobile-nav-overlay.open {
    height: 100vh;
    opacity: 1;
    visibility: visible;
}

/* Close Button */
.close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1001;
}

.close-icon {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.close-icon::before,
.close-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
}

.close-icon::before {
    transform: rotate(45deg);
}

.close-icon::after {
    transform: rotate(-45deg);
}

/* Mobile Navigation List */
.mobile-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    transform: translateY(-20px);
    opacity: 0;
    transition: all 0.3s ease;
}

.mobile-nav-overlay.open .mobile-nav-list {
    transform: translateY(0);
    opacity: 1;
}

.mobile-nav-list li {
    margin: 1.5rem 0;
}

.mobile-nav-list button {
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.mobile-nav-list button:hover {
    color: #ddd;
    transform: scale(1.05);
}

@media (max-width: 1200px) {
    .navbar {
        border-radius: 100px;
        transition: all 0.2s ease;
        width: 80vw;
        z-index: 1000;
    }

    .navbar-top {
        position: absolute;
        top: 2vw;
        left: 0;
        padding: 1rem;
        color: #fff;
        background-color: transparent;
        transform: translateX(0);
        transition: all 0.5s ease;
    }

    .navbar-scrolled {
        border-radius: 100px;
        position: fixed;
        top: 4vh;
        left: 50%;
        transform: translateX(-50%);
        width: 80vw;
        background-color: #fff;
        color: black;
        padding: 1rem 0;
        transition: all 0.7s ease;
    }

    .nav-list button {
        background: none;
        border: none;
        color: inherit;
        font-weight: bold;
        font-size: 2vw;
        cursor: pointer;
        position: relative;
        font-family: sans-serif;
        padding: 0.6em 1.2em;
    }

    .nav-list button::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: currentColor;
        transition: width 0.3s ease;
    }

    .nav-list button:hover::after {
        width: 100%;
    }

    .nav-list button:hover {
        color: #ddd;
    }

    .navbar-scrolled .nav-list button:hover {
        color: #333;
    }
}

@media (max-width: 600px) {
    .navbar {
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        transform: translateX(0);
        padding: 0;
    }

    .navbar-top,
    .navbar-scrolled {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.1);
        color: #fff;
        width: 100%;
        padding: 0;
        transform: none;
        left: 0;
        top: 0;
    }

    .hamburger {
        display: flex;
    }

    .nav-list {
        display: none;
    }
}

@media (max-width: 600px) {

    /* Ensure the navbar is full width in this tablet range */
    .navbar {
        width: 100% !important;
    }

    /* Make mobile-header appear on tablets so we can place the hamburger to the right */
    .mobile-header {
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
    }

    /* Show the hamburger and move it all the way to the right */
    .hamburger {
        display: flex !important;
        margin-left: auto;
        margin-right: 2rem;
    }

    /* Optionally, hide the desktop nav-list in this range if you only want the hamburger menu */
    .nav-list {
        display: none !important;
    }
}

.mobile-header {
    position: fixed;
    top: 0;
    left: 1rem;
    width: 100%;
    padding: 1rem;
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    z-index: 1101;
    background: transparent;
}

.hamburger {
    position: relative;
    width: 60px;
    height: 60px;
    background: transparent;
    border: none;
    cursor: pointer;
    overflow: visible;
    z-index: 1100;
    /* increased margin to move further right */
}

.hamburger .bar {
    position: absolute;
    left: 50%;
    width: 70%;
    /* longer bars */
    height: 3px;
    /* thinner bars */
    background-color: white;
    transform: translateX(-50%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform-origin: center;
}

.hamburger .bar:nth-child(1) {
    top: 16px;
}

.hamburger .bar:nth-child(2) {
    top: 27px;
}

.hamburger .bar:nth-child(3) {
    top: 38px;
}

.hamburger.open .bar:nth-child(1) {
    transform: translateX(-50%) translateY(11px) rotate(45deg);
}

.hamburger.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
    transform: translateX(-50%) translateY(-11px) rotate(-45deg);
}