.introduction {
    /* Fill the entire viewport height */
    height: 100vh;
    width: 100%;

    /* Center elements */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* Prevent internal scrolling; the parent container handles page scroll */
    overflow: hidden;

    position: relative;
}

.intro-elements {
    /* Fill the .introduction section’s space */
    width: 100%;
    height: 100%;

    /* Center content */
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 100;
}

/* Intro text styling */
.intro-text {
    color: white;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.6);
    font-size: 4vw;
    font-family: "Lato", sans-serif;
    position: relative;
    z-index: 1;
    text-align: center;
    line-height: 1.5;
    white-space: pre-line;
    padding: 0 2rem;
}

.spline-resize {
    position: absolute;
    width: 80%;
    height: 80%;
    z-index: 0;
    overflow: hidden;

    /* If you don't need the transition, remove it */
    transition: width 0.3s ease, height 0.3s ease;
}

.spline-scene-intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.2);
    transform-origin: center;

    /*
      Optional: disable pointer events so the Spline doesn't hijack scroll on mobile.
      Remove or comment out if you need interactivity within the 3D scene.
    */
    pointer-events: none;
}

/* Breakpoints */
@media (max-width: 1200px) {
    .spline-resize {
        width: 70%;
        height: 70%;
    }

    .intro-text {
        font-size: 4.5vw;
        padding: 0 1.5rem;
    }
}

@media (max-width: 992px) {
    .spline-resize {
        width: 60%;
        height: 60%;
    }

    .intro-text {
        font-size: 5vw;
        padding: 0 1rem;
    }
}

@media (max-width: 768px) {
    .spline-resize {
        width: 50%;
        height: 50%;
    }

    .intro-text {
        font-size: 6vw;
        padding: 0 1rem;
    }
}

@media (max-width: 576px) {
    .spline-resize {
        width: 40%;
        height: 40%;
    }

    .intro-text {
        font-size: 7vw;
        padding: 0 0.5rem;
    }
}

@media (max-width: 400px) {
    .spline-resize {
        width: 30%;
        height: 30%;
    }

    .intro-text {
        font-size: 8vw;
        padding: 0 0.3rem;
    }
}