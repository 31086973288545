* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about-container {
    min-height: 100vh;
    color: #fff;
    padding: 4rem 2rem;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Reduced blur from 100px to 60px for better performance */
.gradient-circle-1,
.gradient-circle-2,
.gradient-circle-3 {
    position: absolute;
    border-radius: 50%;
    filter: blur(60px);
    opacity: 0.15;
    pointer-events: none;
}

.gradient-circle-1 {
    background: #3b82f6;
    width: 50%;
    height: 50%;
    top: -10%;
    right: -10%;
}

.gradient-circle-2 {
    background: #8b5cf6;
    width: 40%;
    height: 40%;
    bottom: -10%;
    left: -10%;
}

.gradient-circle-3 {
    background: #06b6d4;
    width: 30%;
    height: 30%;
    bottom: 20%;
    right: 20%;
}

.about-title {
    text-align: center;
    font-size: clamp(1.8rem, 5vw, 3rem);
    margin-bottom: 1rem;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 2;
}

.about-subtitle {
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    color: #94a3b8;
    max-width: 800px;
    margin: 0 auto 4rem;
    position: relative;
    z-index: 2;
    line-height: 1.4;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 1rem;
}

/* Reduced backdrop-filter blur from 10px to 5px */
.feature-card {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    padding: 2rem;
    text-align: center;
    backdrop-filter: blur(5px);
    transition: transform 0.3s ease, background 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-10px);
    background: rgba(255, 255, 255, 0.05);
}

.feature-icon-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}


.feature-title {
    font-size: clamp(1.2rem, 3.5vw, 1.5rem);
    margin-bottom: 1rem;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.feature-description {
    color: #94a3b8;
    line-height: 1.6;
    font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.cta-section {
    text-align: center;
    margin-top: 6rem;
    position: relative;
    z-index: 2;
    width: 100%;
}

.cta-title {
    font-size: clamp(1.5rem, 4vw, 2rem);
    margin-bottom: 2rem;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cta-button {
    background: linear-gradient(45deg, #3b82f6, #8b5cf6);
    border: none;
    color: white;
    padding: 1rem 2rem;
    border-radius: 0.75rem;
    font-size: clamp(1rem, 2.5vw, 1.125rem);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    transition: all 0.3s ease;
}

.cta-button:hover {
    box-shadow: 0 0 15px rgba(59, 130, 246, 0.5);
}

/* Media Queries */
@media (max-width: 1024px) {
    .about-container {
        padding: 3rem 1rem;
    }

    .features-grid {
        gap: 1.5rem;
    }

    .feature-card {
        padding: 1.5rem;
    }

    .cta-section {
        margin-top: 4rem;
    }
}

@media (max-width: 640px) {
    .about-subtitle {
        margin-bottom: 3rem;
        padding: 0 1rem;
    }

    .cta-button {
        width: 100%;
        justify-content: center;
    }
}