@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.landing {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    text-align: center;
}

.landing-elements {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    /* so everything is centered vertically */
}

/* MAIN TITLE */
.landing-title {
    position: relative;
    font-family: "Funnel Display", sans-serif;
    font-size: 9vw;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
    word-wrap: break-word;
    z-index: 1;
    margin-bottom: 1rem;
}

.law-part {
    position: relative;
    display: inline-block;
}

.law-part .powered-by {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    color: #94a3b8;
    white-space: nowrap;
    margin-top: 0.2em;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

/* Hover effect */
.law-part .powered-by:hover {
    color: #fff;
}

/* MIDDLE TEXT */
.landing-middle {
    z-index: 1;
    font-size: 2vw;
    font-family: "Lato", sans-serif;
    max-width: 80%;
    line-height: 1.5;
    word-wrap: break-word;
    margin-top: 2rem;
}

/* CTA BUTTON */
.landing-button {
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    padding: 0.5rem 2rem;
    margin-top: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50px;
    font-family: "Funnel Display", sans-serif;
    font-weight: 700;
    font-size: 2vw;
    z-index: 1;
    white-space: nowrap;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.landing-button:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(139, 92, 246, 0.7);
}

/* ---- BREAKPOINTS ---- */

@media (max-width: 1200px) {
    .landing-title {
        font-size: 12vw;
    }

    .landing-middle {
        font-size: 3vw;
        padding: 0 2rem;
    }

    .landing-button {
        font-size: 3.5vw;
    }
}

@media (max-width: 992px) {
    .landing-title {
        font-size: 14vw;
    }

    .landing-middle {
        font-size: 3.5vw;
        padding: 0 1.5rem;
    }

    .landing-button {
        font-size: 4vw;
        padding: 0.6rem 1.5rem;
    }
}

@media (max-width: 768px) {
    .landing-title {
        font-size: 16vw;
    }

    .landing-middle {
        font-size: 4vw;
        padding: 0 1rem;
    }

    .landing-button {
        font-size: 4.5vw;
        padding: 0.8rem 1.2rem;
    }
}

@media (max-width: 576px) {
    .landing-title {
        font-size: 18vw;
    }

    .landing-middle {
        font-size: 4.5vw;
        padding: 0 0.5rem;
    }

    .landing-button {
        font-size: 5vw;
        padding: 1rem 1rem;
    }
}

/* 
  On phone screens up to 400px (or choose your own breakpoint),
  remove the absolute positioning so "Powered By" is centered
  UNDER the entire "Orbilaw" text.
*/
@media (max-width: 500px) {
    .landing-title {
        font-size: 20vw;
        display: flex;
        align-items: center;
        flex-direction: row;

    }

    .landing-middle {
        font-size: 5vw;
    }

    .landing-button {
        font-size: 5.5vw;
        padding: 1.2rem 1rem;
    }

    /* 
    Remove absolute positioning so it falls in normal flow,
    and center it under "Orbilaw".
  */
    .law-part {
        position: static;
        display: block;
        text-align: center;
    }

    .law-part .powered-by {
        position: static;
        margin-top: 0.5rem;
        left: auto;
        transform: none;
        display: block;
    }
}